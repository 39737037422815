import { createSlice } from '@reduxjs/toolkit'
import * as R from 'ramda'
import {round} from '../utils'

const isNumber = (value) =>  !(value === null || value === undefined || isNaN(value))
const convertToNumberIfRequired = (value) => {
  if(value === "")
    return null;
  return typeof value === 'string' || value instanceof String ? Number(value) : value
}


const hasAnyErrors = (state) => {
  const rp = state.routePreferences;
  const hasAdditionalLocationsErrors = R.flatten(rp.additionalLocationsValidationErrors).some(a => !!a);

  const hasAxLimitsErrors = R.flatten(rp.axLimitsErrors).some(a => !!a)
  const hasAyLimitsErrors = R.flatten(rp.ayLimitsErrors).some(a => !!a)
  const hasAzLimitsErrors = R.flatten(rp.azLimitsErrors).some(a => !!a);

  return hasAdditionalLocationsErrors
    || hasAxLimitsErrors
    || hasAyLimitsErrors
    || hasAzLimitsErrors
    || rp.maximumWaveHeightError
    || rp.minimumOperabilityError;
}

const initialState = {
  isLoading: false,
  applyPreferencesLoading: false,
  isError: false,
  modalVisible: false,
  checkboxes: [
    {
      checked: false,
      name: 'MaximumAcceleration',
    },
    {
      checked: false,
      name: 'MaximumWaveHeight',
      value: null,
    },
    {
      checked: false,
      name: 'MinimumOperability',
      value: null,
    },
  ],
  seasonStartDate: null,
  seasonEndDate: null,

  traversAccelerations: {
    ax: [[null], [null], [null], [null]],
    ay: [
      [null, null, null, null, null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null, null, null, null, null],
    ],
    az: [[null, null, null, null, null, null, null, null, null, null, null]],
  },


  customAccelerations: [],

  axLimitsErrors: [[false], [false], [false], [false]],
  ayLimitsErrors: [
    [false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false],
  ],
  azLimitsErrors: [[false, false, false, false, false, false]],

  additionalLocationsValidationErrors: [],


  maximumWaveHeightError: false,
  minimumOperabilityError: false,

  hasAnyErrors: false,

  previouseState: null,
  comments: null,
};

export const routePSlice = createSlice({
  name: 'routePreferences',
  initialState: {...initialState},
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
      if (action.payload) {
        state.isError = false
      }
    },

    setIsError: (state, action) => {
      state.isError = !!action.payload
    },

    setChecked: (state, action) => {
      const { index, value } = action.payload
      state.checkboxes.forEach((v, i) => {
        v.checked = i === index ? value : false
      })
    },

    setCheckedValue: (state, action) => {
      const { index, value } = action.payload
      state.checkboxes.forEach((v, i) => {
        v.value = i === index ? value : v.value
      })
    },

    setSeason: (state, action) => {
      state.season = action.payload
    },

    updateTraversAccelerations: (state, action) => {
      const { direction, row, col, value } = action.payload
      if (isNumber(value)) {
        const valueConverted = convertToNumberIfRequired(value)
        state.traversAccelerations[direction][row][col] = valueConverted
      }
    },

    updateCustomAccelerations: (state, action) => {
      const { row, col, value } = action.payload
      if (isNumber(value)) {
        const valueConverted = convertToNumberIfRequired(value)
        state.customAccelerations[row][col] = valueConverted
      }
    },

    updateAdditionalLocations: (state, action) => {
      const { row, col, value } = action.payload
      if(col === 0) {
        state.customAccelerations[row][col] = value
      }
      else if (isNumber(value)) {
        const valueConverted = convertToNumberIfRequired(value)
        state.customAccelerations[row][col] = valueConverted
      }
    },

    removeAdditionlLocationRow: (state, action) => {
      const { rowIndex } = action.payload
      state.customAccelerations.splice(rowIndex, 1)
    },

    addAdditionLocaiontRow: (state, action) => {
      state.customAccelerations.push([null, null, null, null, null, null])
      state.additionalLocationsValidationErrors.push([false, false, false, false, false, false])
    },

    setStartEndSeason: (state, action) => {
      state.seasonStartDate = action.payload.seasonStartDate
      state.seasonEndDate = action.payload.seasonEndDate
    },

    setModalVisible: (state, action) => {

      let {flag, previouseState} = action.payload


      // if open
      if(flag && previouseState) {
        state.previouseState = previouseState
      } else if(flag === false && previouseState) {
        // if close
        // skip this properties
        //['isLoading', 'applyPreferencesLoading', 'isError', 'modalVisible', 'hasAnyErrors', 'previouseState']

        state.checkboxes = previouseState.checkboxes;
        state.seasonStartDate = previouseState.seasonStartDate
        state.seasonEndDate = previouseState.seasonEndDate;
        state.traversAccelerations = previouseState.traversAccelerations
        state.customAccelerations = previouseState.customAccelerations
        state.axLimitsErrors = previouseState.axLimitsErrors
        state.ayLimitsErrors = previouseState.ayLimitsErrors
        state.azLimitsErrors = previouseState.azLimitsErrors
        state.additionalLocationsValidationErrors = previouseState.additionalLocationsValidationErrors
        state.maximumWaveHeightError = previouseState.maximumWaveHeightError
        state.minimumOperabilityError = previouseState.minimumOperabilityError


        state.previouseState = null
      }

      state.modalVisible = flag
    },

    setAddtionalLocatinValidationErrors: (state, action) => {
      state.additionalLocationsValidationErrors = action.payload
    },

    setApplyPreferencesLoading: (state, action) => {
      state.applyPreferencesLoading = action.payload
    },

    setStandarLocAccLimitsErrors: (state, action) => {
      const { axLimitsErrors, ayLimitsErrors, azLimitsErrors } = action.payload
      state.axLimitsErrors = axLimitsErrors
      state.ayLimitsErrors = ayLimitsErrors
      state.azLimitsErrors = azLimitsErrors
    },

    setMaximumWaveHeightError: (state, action) => {
      state.maximumWaveHeightError = action.payload
    },

    setMinimumOperabilityError: (state, action) => {
      state.minimumOperabilityError = action.payload
    },

    setComments: (state, action) => {
      state.comments = action.payload
    },

    setHasAnyErrors: (state, action) => {
      state.hasAnyErrors = action.payload
    }
  },
  extraReducers: {
    "voyage/copyVoyage": (_, action) => {

      const _state = {...initialState}

      _state.seasonStartDate = action.payload.seasonStartDate;
      _state.seasonEndDate = action.payload.seasonEndDate;

      _state.customAccelerations = action.payload.customAccelerations


      _state.traversAccelerations = {
        ax: action.payload.standardPointsAccelerationLimitsAx.map(a => [a]),
        ay: action.payload.standardPointsAccelerationLimitsAy,
        az: [action.payload.standardPointsAccelerationLimitsAz],
      }

      _state.checkboxes = _state.checkboxes.map(c => ({...c}))
      _state.checkboxes.forEach(c => {
        if(c.name === 'MaximumAcceleration' && action.payload.hasStandardPointsAccelerationLimits) {
          c.checked = true
        }
        if(c.name === 'MaximumWaveHeight' &&  action.payload.maximumWaveHeight !== null) {
          c.value = action.payload.maximumWaveHeight;
          c.checked = true;
        }

        if(c.name === 'MinimumOperability' && action.payload.minimumOperability !== null) {
          c.value = action.payload.minimumOperability;
          c.checked = true
        }
      })

      _state.comments = action.payload.comments;
      return _state;
    }
  }
})

const { actions } = routePSlice

// validators
const accValidator = (value) => {
  if(isNumber(value)) {
    return (value > 0 && value < 30)
  } else {
    return true;
  }
}

const additionLocationLineValidator = (line, vessel) => {
  //let name = line[0];
  let x = line[1];
  let z = line[2];
  let ax = line[3];
  let ay = line[4];
  let az = line[5];

  const xMin = round(-0.1 * vessel.lpp, 2);
  const xMax = round( 1.1 * vessel.lpp, 2)
  const zMax = round(4 * vessel.depth, 2)

  let xErr = isNumber(x) && (x >= xMin && x <= xMax);
  let zErr = isNumber(z) && (z > 0 && z <= zMax)


  return [false, !xErr, !zErr, !accValidator(ax) , !accValidator(ay), !accValidator(az)]
}

const additionLocationsValidator = (additionalLocations, vessel) => additionalLocations.map(line => additionLocationLineValidator(line, vessel))

const standardLocationAccValidator = (ax, ay, az, vessel) => {
  const axLimitsErrors = ax.map(a => a.map(acc => !accValidator(acc)))
  const ayLimitsErrors = ay.map(a => a.map(acc => !accValidator(acc)))
  const azLimitsErrors = az.map(a => a.map(acc => !accValidator(acc)))

  return {
    axLimitsErrors,
    ayLimitsErrors,
    azLimitsErrors
  }
}

export const setChecked = (index, value) => {
  return actions.setChecked({ index, value })
}

export const setCheckedValue = (index, value) => (dispatch, getState) => {
  dispatch(actions.setCheckedValue({ index, value }))

  const state = getState();
  if(index === 1)
  {
    const waveHeight = state.routePreferences.checkboxes[index].value
    const waveHeightErr = isNumber(waveHeight) ? waveHeight >= 1 : true;
    dispatch(actions.setMaximumWaveHeightError(!waveHeightErr))
  } else if(index === 2) {
    const minOpe = state.routePreferences.checkboxes[index].value
    const minOpeErr = isNumber(minOpe) ? minOpe > 0 && minOpe <= 100 : true;
    dispatch(actions.setMinimumOperabilityError(!minOpeErr))
  }

  const anyErrors = hasAnyErrors(getState())
  dispatch(actions.setHasAnyErrors(anyErrors))

}

export const setSeason = (momentDateRange) => {
  if (
    Array.isArray(momentDateRange) &&
    momentDateRange.length === 2 &&
    momentDateRange[0].isValid() &&
    momentDateRange[1].isValid()
  ) {
    return actions.setStartEndSeason({
      seasonStartDate: momentDateRange[0].format('YYYY-MM-DD'),
      seasonEndDate: momentDateRange[1].format('YYYY-MM-DD'),
    })
  }
  return actions.setStartEndSeason({
    seasonStartDate: null,
    seasonEndDate: null,
  })
}

export const updateTraversAccelerations = (direction, row, col, value) => (dispatch, getState) => {
  dispatch(actions.updateTraversAccelerations({ direction, row, col, value }))

  const state = getState();
  const vessel = state.vessels.data.vessels[state.newVoyage.vessel];

  var validationResult = standardLocationAccValidator(
    state.routePreferences.traversAccelerations.ax,
    state.routePreferences.traversAccelerations.ay,
    state.routePreferences.traversAccelerations.az,
    vessel);
    dispatch(actions.setStandarLocAccLimitsErrors(validationResult))
    const anyErrors = hasAnyErrors(getState())
    dispatch(actions.setHasAnyErrors(anyErrors))
}

export const updateCustomAccelerations = (row, col, value) => (dispatch, getState) => {
  dispatch(actions.updateCustomAccelerations({ row, col, value }))

  // validation
  const state = getState();
  const vessel = state.vessels.data.vessels[state.newVoyage.vessel]
  var validationResult = additionLocationsValidator(state.routePreferences.customAccelerations, vessel)
  dispatch(actions.setAddtionalLocatinValidationErrors(validationResult))

  const anyErrors = hasAnyErrors(getState())
  dispatch(actions.setHasAnyErrors(anyErrors))
}

export const updateAdditionalLocations = (row, col, value) => (dispatch, getState) => {
  dispatch(actions.updateAdditionalLocations({ row, col, value }))

  // validation
  const state = getState();
  const vessel = state.vessels.data.vessels[state.newVoyage.vessel]
  var validationResult = additionLocationsValidator(state.routePreferences.customAccelerations, vessel)
  dispatch(actions.setAddtionalLocatinValidationErrors(validationResult))

  const anyErrors = hasAnyErrors(getState())
  dispatch(actions.setHasAnyErrors(anyErrors))
}

export const { removeAdditionlLocationRow, addAdditionLocaiontRow } = actions

export const setModalVisible = (flag) => (dispatch, getState) => {
  const {routePreferences} = getState();

  // if open command
  if(flag) {
    const previouseState = R.omit(['isLoading', 'applyPreferencesLoading', 'isError', 'modalVisible', 'hasAnyErrors', 'previouseState'], routePreferences)
    dispatch(actions.setModalVisible({flag, previouseState}))
  } else {
    // if close command

    dispatch(actions.setModalVisible({flag, previouseState: routePreferences.previouseState}));
  }
}


export const applyPreferences = () => (dispatch, getState) => {

  //dispatch(actions.setApplyPreferencesLoading(true))

  //const state = getState();
  //var vessel = state.vessels.vessels.find(v => v.id === state.newVoyage.vessel) //[state.newVoyage.vessel]
  //const additonalLocations = state.routePreferences.customAccelerations;
  //const res = isAdditionalLocationsValid(additonalLocations, vessel.lpp, vessel.depth)
  //var axStandarLocationsErrors = isStandarLocatonsAxAccLimitValid(state.routePreferences.traversAccelerations.ax)
  //const additionalLocationsErrors = res.map(d => d.map(a => !a));

  //dispatch(actions.setAddtionalLocatinValidationErrors(additionalLocationsErrors));

  // setTimeout(() => {
  //   // const isError = hasErrors(getState())

  //   // if(!isError) {
  //   //   dispatch(actions.setModalVisible(false))
  //   // }
  //   // dispatch(actions.setApplyPreferencesLoading(false))
  // }, 1000);

  dispatch(actions.setModalVisible(false))
}

export const setComments = (text) => {
  return actions.setComments(text)
}

