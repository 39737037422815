import React from 'react'
import './DNVSpinner.css'

var lineSize = 3
var space = 0.13636
var round10 = function(num) {
  return Math.round(num * 10) / 10
}

const DNVSpinner = props => {
  var { air, sea, land, label, labelPosition, speed, size } = props

  var originXAir = size / 2
  var originYAir = originXAir + round10(lineSize / 2)
  var airBox =
    '0px ' + lineSize + 'px ' + Math.floor(lineSize * 0.3) + 'px 0 #99d9f0'
  var sizeLand = round10(size * (1 - space))
  var posLand = round10((size - sizeLand) / 2)
  var originXLand = sizeLand / 2
  var originYLand = originXLand + round10(lineSize / 2)
  var landBox =
    '0px ' + lineSize + 'px ' + Math.floor(lineSize * 0.3) + 'px 0 #36842d'
  var sizeSea = round10(size * (1 - space * 2))
  var posSea = round10((size - sizeSea) / 2)
  var originXSea = sizeSea / 2
  var originYSea = originXSea + round10(lineSize / 2)
  var seaBox =
    '0px ' + lineSize + 'px ' + Math.floor(lineSize * 0.3) + 'px 0 #003591'
  return (
    <div
      className={`vui-spinner ${props.className || ''}`}
      data-speed={speed}
      data-air={air}
      data-sea={sea}
      data-land={land}
      data-label-position={label ? labelPosition : undefined}
    >
      <div
        className="vui-spinner-container"
        style={{ width: size, height: size }}
      >
        <div
          className="vui-spinner-fragment vui-spinner-fragment--air"
          style={{
            width: size,
            height: size,
            transformOrigin: originXAir + 'px ' + originYAir + 'px',
            boxShadow: airBox
          }}
        />
        <div
          className="vui-spinner-fragment vui-spinner-fragment--land"
          style={{
            width: sizeLand,
            height: sizeLand,
            transformOrigin: originXLand + 'px ' + originYLand + 'px',
            left: posLand,
            top: posLand,
            boxShadow: landBox
          }}
        />
        <div
          className="vui-spinner-fragment vui-spinner-fragment--sea"
          style={{
            width: sizeSea,
            height: sizeSea,
            transformOrigin: originXSea + 'px ' + originYSea + 'px',
            left: posSea,
            top: posSea,
            boxShadow: seaBox
          }}
        />
        {label && <span className="vui-spinner__label">{label}</span>}
      </div>
    </div>
  )
}

DNVSpinner.defaultProps = {
  land: false,
  sea: true,
  air: false,
  speed: 'medium',
  size: 220,
  labelPosition: 'right'
}

export default DNVSpinner
