import { createSlice } from '@reduxjs/toolkit'
import { getVessels } from '../api'

export const vesselsSlice = createSlice({
  name: 'vessels',
  initialState: {
    isLoading: false,
    isError: false,
    data: {
      order: [],
      vessels: {}
    },
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
      if (action.payload) {
        state.isError = false
      }
    },

    setIsError: (state, action) => {
      state.isError = !!action.payload
    },

    setVessels: (state, action) => {
      state.data = action.payload
    },
  },
})

const { actions } = vesselsSlice
export const setVessel = actions.setVessels

export const fetchVessels = () => {
  return (dispatch) => {
    dispatch(actions.setIsLoading(true))
    return getVessels()
      .then((response) => response.data)
      .then((vessels) => {
        dispatch(actions.setIsLoading(true))
        dispatch(actions.setVessels(vessels))
        dispatch(actions.setIsLoading(false))
      })
      .catch((e) => dispatch(actions.setIsError(true)))
  }
}
