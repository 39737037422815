import { createSlice } from '@reduxjs/toolkit'
import api from '../api'


export const initSlice = createSlice({
  name: 'init',
  initialState: {
    isLoading: false,
    init: {
      userName: '',
      companyName: ''
    }
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
    },

    setInit: (state, action) => {
      state.init = action.payload
    }
  }
})

const { actions } = initSlice

export const getInit = () => (dispatch) => {
  dispatch(actions.setIsLoading(true))

  api.getInit()
    .then(data => {
      return data.data
    })
    .then(data => {
      dispatch(actions.setInit(data))

      dispatch(actions.setIsLoading(false))
    })
    .catch(e => console.log({e}))
}
