import { createSlice } from '@reduxjs/toolkit'
import { createVessel } from '../api'
import { fetchVessels } from './vesselSlice'
import * as R from 'ramda'

const initialState  = {

  isLoading: false,
  isError: false,
  errors: {},
  hullData: [
    {
      title: '3D - Bare Hull (.iges, .stl or .step format)',
      checked: false,
      files: [],
    },
    {
      title: 'Structural drawings (preferably Lines Plan)',
      checked: false,
      files: [],
    },
    {
      title: 'Generic hull form (no input of IMO Number needed)',
      checked: false,
    },
  ],
  designDraft: null,
  blockCoefficient: null,
  vesselCreated: false,

}

export const createVesselSlice = createSlice({
  name: 'createVessel',
  initialState: {...initialState},
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
      if (action.payload) {
        state.isError = false
      }
    },

    setIsError: (state, action) => {
      state.isError = !!action.payload
    },

    setErrors: (state, action) => {
      state.errors = action.payload
    },

    setHullData: (state, action) => {
      state.hullData = action.payload
    },

    setVesselCreated: (state, action) => {

      return {...initialState, vesselCreated: action.payload}

    },

    setDesignDraft: (state, action) => {
      delete(state.errors.designDraft)
      state.designDraft = action.payload
    },

    setBlockCoefficient: (state, action) => {
      delete(state.errors.blockCoefficient)
      state.blockCoefficient = action.payload
    }
  },
})

const { actions } = createVesselSlice

const extendCreateVesselRequest = (vessel, hullData, designDraft, blockCoefficient) => {
  return {
    ...vessel,
    bareHull3D: hullData[0].checked,
    bareHull3DFileIds: hullData[0].files,
    structuralDrawings: hullData[1].checked,
    structuralDrawingsFileIds: hullData[1].files,
    genericHullForm: hullData[2].checked,
    designDraft: designDraft,
    blockCoefficient: blockCoefficient
  }
}

export const addVessel = (vessel) => (dispatch, getState) => {
  dispatch(actions.setIsLoading(true))

  const state = getState().createVessel;

  const reqData = extendCreateVesselRequest(vessel, state.hullData, state.designDraft, state.blockCoefficient)

  return createVessel(reqData)
    .then(() => {
      dispatch(actions.setVesselCreated(true))
      dispatch(fetchVessels())
      dispatch(actions.setIsLoading(false))
    })
    .catch((error) => {
      if (error.response.status === 400) {
        var errors = error.response.data.errors
        dispatch(actions.setErrors(errors))
        dispatch(actions.setIsLoading(false))
        return Promise.reject(errors)
      }
      //dispatch(actions.setIsError(true))
    })
}

export const onHullFormChecked = (index, value) => (dispatch, getState) => {
  const hullData = getState().createVessel.hullData

  const newValue = hullData.map((c, idx) => ({
    ...c,
    checked: idx === index && value,
  }))
  dispatch(actions.setHullData(newValue))
}

export const onFileChange = (index, file, fileList) => (dispatch, getState) => {
  const hullData = getState().createVessel.hullData

  let fileIds = fileList
    .filter((f) => f.response)
    .map((f) => f.response.map((r) => r.id))

  fileIds = R.uniq(R.flatten(fileIds))

  const newValue = hullData.map((c, idx) => ({
    ...c,
    files: idx === index ? fileIds : c.files,
  }))
  dispatch(actions.setHullData(newValue))
}

export const setVesselCreated = (val) => actions.setVesselCreated(val)

export const setDesignDraft = (val) => actions.setDesignDraft(val)
export const setBlockCoefficient = (val) => actions.setBlockCoefficient(val)
