import { createSlice } from '@reduxjs/toolkit'
import {checkIfInputsAreValid} from './calculationSlice'

const initialState = {
  isLoading: false,

  data: {
    draft: null,
    trim: null,
    speed: null,
    height: null,
  },
  isCalculationReady: false,

  fields: [
    {
      name: ['draft'],
    },{
      name: ['trim'],
    },{
      name: ['speed'],
    },{
      name: ['height'],
    }
  ]
};

export const operationalConditionSlice = createSlice({
  name: 'operationalCondition',
  initialState: {...initialState},
  reducers: {
    setIsCalculationReady: (state, action) => {
      state.isCalculationReady = action.payload
    },

    setFields: (state, action) => {
      state.fields = action.payload
      const data = action.payload.reduce((prev, next) => ({...prev, [next.name[0]]: next.value})  ,{})
      state.data = data
    }
  },
  extraReducers: {
    "voyage/copyVoyage": (state, action) => {

      state.isLoading = false
      state.isCalculationReady = true

      // old implementation of data holder
      state.data.draft = action.payload.draft
      state.data.trim = action.payload.trim
      state.data.speed = action.payload.speed
      state.data.height = action.payload.height

      const fields = [
        {
          name: ['draft'],
          value: action.payload.draft,
          touched: true,
          errors: []
        },{
          name: ['trim'],
          value: action.payload.trim,
          touched: true,
          errors: []
        },{
          name: ['speed'],
          value: action.payload.speed,
          touched: true,
          errors: []
        },{
          name: ['height'],
          value: action.payload.height,
          touched: true,
          errors: []
        }
      ]

      state.fields = fields
    }
  }
})

const { actions } = operationalConditionSlice

export const setFields = values => (dispatch, getState) => {
  dispatch(actions.setFields(values))

  const allFieldsValid = values.every(({touched, errors}) => {
    return touched && errors.length == 0;
  })

 dispatch(actions.setIsCalculationReady(allFieldsValid))
 dispatch(checkIfInputsAreValid())
}
