import { createSlice } from '@reduxjs/toolkit'
import { getArchiveVoyages, cloneVoyage } from '../api'
import {cloneRouteInputs} from './routeInputsSlice'

export const voyageSlice = createSlice({
  name: 'voyage',
  initialState: {
    isLoading: false,
    isError: false,
    voyages: []
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
      if (action.payload) {
        state.isError = false
      }
    },

    setIsError: (state, action) => {
      state.isError = !!action.payload
    },

    setVoyages: (state, action) => {
      state.voyages = action.payload
    },

    copyVoyage: (state, action) => {

    }
  },
})

const { actions } = voyageSlice

export const fetchVoyages = () => dispatch => {
  dispatch(actions.setIsLoading(true))
  return getArchiveVoyages()
    .then(res => {
      dispatch(actions.setVoyages(res.data))
      dispatch(actions.setIsLoading(false))
    })
    .catch(err => {
      dispatch(actions.setIsError(true))
      dispatch(actions.setIsLoading(false))
    })
}

export const copyVoyage = (id) => dispatch => {

  return cloneVoyage(id)
    .then(res => {
      dispatch(actions.copyVoyage(res.data))
      dispatch(cloneRouteInputs(res.data.routeInputs))
    })
}
