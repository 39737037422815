export const round = (num, precision = 1) => {
  const prec = [10, 100, 1000, 10000, 100000, 1000000]
  if(precision > prec.length)
  {
    throw new Error(`Not supported precition value: ${precision}`)
  }

  const p =  prec[precision - 1];
  if(typeof num === 'number')
  {
    const rounded = (Math.round((num + Number.EPSILON) * p) / p).toFixed(precision);
    return rounded;
  } else
  {
    return num;
  }
}

export const multiplyCoords = (bmt, val) => {
  var newCoords = bmt.geometry.coordinates.map(a => a.map(c => [c[0] + val, c[1]]))
  return {
    ...bmt,
    geometry: {
      ...bmt.geometry,
      coordinates: newCoords
    }
  }
}

export const multiplyGeoJson = (featureCollection) => {
  if(featureCollection && featureCollection.features) {
    return {
      ...featureCollection,
      features: featureCollection.features.reduce((init, curr) => [...init, curr, multiplyCoords(curr, 360), multiplyCoords(curr, -360)], [])
    }
  }

  return featureCollection;
}
