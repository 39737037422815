import { createSlice } from '@reduxjs/toolkit'
import {checkIfInputsAreValid} from './calculationSlice'

const isCalculationReady = (state) => {
  const vesselId = state.newVoyage.fields[0].value
  const vesselName = state.newVoyage.fields[1].value

  return (!!vesselId && !!vesselName)
}

const initialState =  {
  isLoading: false,
  isError: false,
  vessel: null,

  isCalculationReady: false,

  fields: [{
    name: ["vessel"],
    value: null
  },{
    name: ["voyageName"],
    value: ""
  }]
}

export const addNewVoyageSlice = createSlice({
  name: 'addNewVoyageSlice',
  initialState: {...initialState},
  reducers: {
    update: (state, action) => {
      const fields = action.payload;
      state.fields = fields
      state.vessel = fields[0].value
    },

    setIsCalculationReady: (state, action) => {
      state.isCalculationReady = action.payload
    }
  },
  extraReducers: {
    "voyage/copyVoyage": (state, action) => {
      //reset all fields
      state.isLoading = false
      state.isError = false
      state.isCalculationReady = false

      state.vessel = action.payload.vesselId
      state.fields[0].value = action.payload.vesselId
      state.fields[1].value =`Copy ${action.payload.voyageName}`;
      state.isCalculationReady = true
    }
  }
})

const { actions } = addNewVoyageSlice

export const update = (fields) => (dispatch, getState)=> {
  dispatch(actions.update(fields))
  const isReady = isCalculationReady(getState());
  dispatch(actions.setIsCalculationReady(isReady))
  dispatch(checkIfInputsAreValid())
}


