import { createSlice } from '@reduxjs/toolkit'
import { getRoute, getHexs } from '../api'
import {checkIfInputsAreValid} from './calculationSlice'
import {multiplyGeoJson} from '../utils'

const postionMappper = {
  port: (input) => ({ Type: 'port', Id: input.selectedPort.id }),
  waypoint: (input) => ({ Type: 'waypoint', lon:  input.latlng.lng,  lat: input.latlng.lat }),
}

const initialState = {
  isLoading: false,
  isError: false,
  id: null,
  route: null,
  timestamp: null,
  isCalculationReady: false,
  hexs: null
}

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
      if (action.payload) {
        state.isError = false
      }
    },

    setIsError: (state, action) => {
      state.isError = !!action.payload
    },

    setRouteWithAreas: (state, action) => {
      state.timestamp = Date.now()
      const { id, route } = action.payload
      state.route = route
      state.id = id
    },

    reset: (state) => {
      return  { ...initialState, timestamp: Date.now(), hexs: state.hexs }
    },

    setIsCalculationReady: (state, action) => {
      state.isCalculationReady = action.payload
    },

    setHexs: (state, action) => {
      // state.hexs = Object.freeze(action.payload)
      state.hexs = JSON.stringify(action.payload)
    }
  },

  extraReducers: {
    "voyage/copyVoyage": (state, action) => {
      //reset all fields
      state = {...initialState}

      state.vessel = action.payload.vesselId
      state.voyageName = `Copy ${action.payload.voyageName}`;
    }
  }
})

const { actions } = mapSlice

export const updateRoute = () => (dispatch, getState) => {
  let state = getState()
  let path = state.routeInputs.inputs
    .filter((f) => !!f.selectedPort || !!f.latlng)
    .map((i) => postionMappper[i.type](i))

  if (path.length > 1) {
    dispatch(actions.setIsLoading(true))
    return getRoute(path).then((res) => {
      const { id, route } = res.data


      dispatch(
        actions.setRouteWithAreas({
          route: route,
          // bmts: filterAreas(areas.bmtsWithProc),
          id,
        })
      )


      dispatch(actions.setIsLoading(false))
      const s = getState();
      dispatch(actions.setIsCalculationReady(!!s.map.id))
      dispatch(checkIfInputsAreValid())
    })
  } else {
    dispatch(actions.reset())
    dispatch(checkIfInputsAreValid())
  }
}

export const fetchHexs = () => (dispatch) => {
  return getHexs()
    .then(res => res.data)
    .then(data => {

      dispatch(actions.setHexs(multiplyGeoJson(data)))
    })
}
