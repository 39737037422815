import React, { Suspense, lazy } from 'react'
import {connect} from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import DNVSpinner from './components/DNVSpinner'
const FrontPage = lazy(() => import('./pages/FrontPage'))
// const ResultPage = lazy(() => import('./pages/ResultPage'))
const ResultPage2 = lazy(() => import('./pages/ResultPage2'))
const ArchivePage = lazy(() => import('./pages/ArchivePage'))
const AboutPage = lazy(() => import('./pages/AboutPage'))
const HelpPage = lazy(() => import('./pages/HelpPage'))
const MyVesselsPage = lazy(() => import('./pages/MyVesselsPage'))

function App({isAuthenticated}) {

  return (
    <div className="App">
    { !isAuthenticated ? <DNVSpinner land={true} sea={true} air={true} /> :

      <>
      <Router>
        <Suspense fallback={<DNVSpinner land={true} sea={true} air={true} />}>
          <Switch>
            <Route path="/" exact component={FrontPage} />
            <Route path="/myvessels" exact component={MyVesselsPage} />
            <Route path="/about" exact component={AboutPage} />
            <Route path="/help" exact component={HelpPage} />
            <Route path="/archive" exact component={ArchivePage} />
            <Route path="/result/:id" component={ResultPage2} />

          </Switch>
        </Suspense>
      </Router>
      </>
  }
    </div>
  )
}

const mapState2Props = state => ({
  isAuthenticated: state.init.init.userName.length > 0
})

export default connect(mapState2Props)(App)
