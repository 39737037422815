import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import * as R from 'ramda'
import {notification} from 'antd'
import {disableBackButton} from './resultSlice'

const initialState = {
  isLoading: false,
  errors: [],

  isCalculationReady: false,
}

export const calculationSlice = createSlice({
  name: 'calculation',
  initialState: {...initialState},

  reducers: {
    isLoading: (state, action) => {
      state.isLoading = action.payload
    },
    doCalculation: (state, action) => {},

    setErrors: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload
    },

    clearErrors: (state, action) => {
      state.errors = []
    },

    setIsCalculationReady: (state, action) => {
      state.isCalculationReady = action.payload
    }
  },
  extraReducers: {
    "voyage/copyVoyage": (state, action) => {
      state.initialState = {...initialState}
    }
  }
})

const { actions } = calculationSlice

const getVoyage = (state) => ({
  vessel: state.newVoyage.fields[0].value,
  voyageName: state.newVoyage.fields[1].value
})

const getRoutePreferences = (state) => {
  return {
    checkboxes: state.routePreferences.checkboxes,
    additionalLocations: state.routePreferences.customAccelerations.map(a => ({name: a[0], x: a[1], z: a[2], ax: a[3], ay: a[4], az: a[5]})),
    traversAccelerations: {
      ax: R.flatten(state.routePreferences.traversAccelerations.ax),
      ay: state.routePreferences.traversAccelerations.ay,
      az: R.flatten(state.routePreferences.traversAccelerations.az),
    },
    seasonStartDate: state.routePreferences.seasonStartDate,
    seasonEndDate: state.routePreferences.seasonEndDate,
  }
}

const getRouteId = (state) => state.map.id

const getOperationalCondition = (state) => ({
  ...state.operationalCondition.data,
})


export const doCalculation = () => (dispatch, getState) => {
  dispatch(actions.isLoading(true))
  var state = getState()
  var req = {
    newVoyage: getVoyage(state),
    routePreferences: getRoutePreferences(state),
    routeId: getRouteId(state),
    operationalCondition: getOperationalCondition(state),
    comments: state.routePreferences.comments
  }

  return api.calculation.do(req)
    .then((data) => {
      dispatch(actions.isLoading(false))
      return data.data;
    })
    .catch((error) => {

      if (error.response && error.response.status === 400) {
       dispatch(actions.setErrors(error.response.data));
      }
    })
}

export const checkIfInputsAreValid = () => (dispatch, getState) => {
  const state = getState();

  const newVoyageCalculationReady = state.newVoyage.isCalculationReady
  const mapReady = state.map.isCalculationReady
  const operationalConditionReady = state.operationalCondition.isCalculationReady

  const ready = newVoyageCalculationReady
                && mapReady
                && operationalConditionReady;
  dispatch(actions.setIsCalculationReady(ready))
}

export const clearErrors = () => actions.clearErrors()

export const acceptCalculation = (calculationId) => (dispatch, getState) => {

  notification.info({
    message: `Report is being generated. Please wait...`,
  });

  return api.calculation.accept(calculationId)
    .then((data) => {
      if(data.status === 200) {
        dispatch(disableBackButton())
        return true;
      } else {
        throw new Error("Can't create report.")
      }
    })
    .catch(() => {
      Notification.error({
        message: `Can not generate report`,
      })
    })
}
