import { createSlice } from '@reduxjs/toolkit'
import { getPorts } from '../api'
import { v4 as uuidv4 } from 'uuid';
import { updateRoute } from './mapSlice'

const inputs = [
  {
    type: 'port',
    label: 'Loading port',
    dataSource: [],

    selectedPort: null,
  },
  {
    type: 'port',
    label: 'Discharging port',
    dataSource: [],

    selectedPort: null,
  },
]

const portTemplate = {
  type: 'port',
  label: 'Port',
  dataSource: [],
  deleteButton: true,
  selectedPort: null,
}

const waypointTemplate = {
  type: 'waypoint',
  label: 'Waypoint',
  latlng: null,
  deleteButton: true,
}

const inputFactory = {
  port: (idx) => addReactId(portTemplate),
  waypoint: (idx) => addReactId(waypointTemplate),
}

const addReactId = (record) => ({ ...record, reactKey: uuidv4() }) //R.assoc('reactKey', )
const addReactIds = (data) => data.map(addReactId)

export const routeInputsSlice = createSlice({
  name: 'routeInputs',
  initialState: {
    isLoading: false,
    isError: false,
    inputs: addReactIds(inputs),
    activeWaypointIdx: -1,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
      if (action.payload) {
        state.isError = false
      }
    },
    setIsError: (state, action) => {
      state.isError = !!action.payload
    },

    deleteInput: (state, action) => {
      const idx = action.payload;
      state.inputs.splice(idx, 1)
    },

    addInput: (state, action) => {
      let { idx, newPort } = action.payload
      state.inputs.splice(idx + 1, 0, newPort)
    },

    setPorts: (state, action) => {
      let { idx, data } = action.payload
      state.inputs[idx].dataSource = data
    },

    selectPort: (state, action) => {
      let { inputIdx, selectedPort } = action.payload
      state.inputs[inputIdx].selectedPort = selectedPort
    },

    updateActiveWaypointPos: (state, action) => {
      let { inputIdx, latlng } = action.payload
      state.inputs[inputIdx].latlng = latlng
      //state.inputs.splice(idx + 1, 0, newPort)
    },

    setActiveWaypointTempLatLng: (state, action) => {
      const { idx, lat, lng } = action.payload
      state.inputs[idx].tmpLatLng = { lat, lng }
    },

    setActiveWaypointIdx: (state, action) => {
      state.activeWaypointIdx = action.payload
    },
    cloneRouteInputs: (state, action) => {
      state.inputs = action.payload
    },

    changeTab: (state, action) => {
      const {idx, activeKey} = action.payload
      if(activeKey === 'waypoint') {
        state.inputs[idx] = addReactId({...waypointTemplate })
        state.activeWaypointIdx = idx
      } else {
        //if port
        state.inputs[idx] = {...portTemplate}
      }
    }
  },
})

const { actions } = routeInputsSlice

export const deleteInput = (idx) => (dispatch, getState) => {
  const {routeInputs} = getState();

  if(idx === 0 || idx === routeInputs.inputs.length - 1) {
    dispatch(actions.changeTab({idx, activeKey: 'port'}))
  } else {
    dispatch(actions.deleteInput(idx))
  }

  dispatch(updateRoute())
  dispatch(reclculateActiveWaypointIdx())
}

export const addInput = (type, idx, input) => (dispatch) => {
  let factory = inputFactory[type]
  let newPort = factory(idx)
  dispatch(actions.addInput({ idx, newPort }))
  dispatch(reclculateActiveWaypointIdx())
}

export const fetchPorts = (idx, text) => (dispatch) => {
  if (text && text.length <= 2) return

  dispatch(actions.setIsLoading(idx, true))
  return getPorts(text).then((res) => {
    dispatch(actions.setPorts({ idx, data: res.data }))
    dispatch(actions.setIsLoading(false))
  })
}

export const setSelectedPort = (inputIdx, selectedPort) => (dispatch) => {
  dispatch(actions.selectPort({ inputIdx, selectedPort }))
  dispatch(updateRoute())
}

export const updateActiveWaypointPos = (latlng) => (dispatch, getState) => {
  let state = getState()

  let inputIdx = state.routeInputs.activeWaypointIdx

  if (inputIdx > -1) {
    dispatch(
      actions.updateActiveWaypointPos({
        inputIdx,
        latlng: { lat: latlng.lat, lng: latlng.lng },
      })
    )

    dispatch(updateRoute())
    dispatch(reclculateActiveWaypointIdx())
  }
}

export const emptySlotIdx = (inputs) => {
  return inputs.findIndex(
    (input) => !helpers.getSelectedValues[input.type](input)
  )
}

export const helpers = {
  getSelectedValues: {
    port: (input) => input.selectedPort,
    waypoint: (input) => input.latlng,
  },
}

export const changeMousePosition = (latlng) => (dispatch, getState) => {
  let state = getState()

  let inputIdx = state.routeInputs.activeWaypointIdx
  if (inputIdx > -1) {
    dispatch(
      actions.setActiveWaypointTempLatLng({
        idx: inputIdx,
        lat: latlng.lat,
        lng: latlng.lng,
      })
    )
  }
}

export const cloneRouteInputs = (routeInputs) => dispatch => {
  let newRouteInputs = routeInputs.map((record, idx) => {
    if(record.id) {
      // port
      return {
        ...inputFactory['port'](),
        selectedPort: {...record}
      }
    } else {
      return {
        ...inputFactory['waypoint'](),
        latlng: {
          lat: record.lat,
          lng: record.lon
        }
      }
    }
  })

  //add react key if required
  newRouteInputs = newRouteInputs.map((i) => {
    if(!i.reactKey)
      return addReactId(i)
    return i;
  })

  dispatch(actions.cloneRouteInputs(newRouteInputs))
  dispatch(updateRoute())
}

const reclculateActiveWaypointIdx = () => (dispatch, getState) => {
  let state = getState()

  // find first free waypoint slot/input
  let inputIdx = state.routeInputs.inputs.findIndex(
    (i) => i.type === 'waypoint' && !i.latlng
  )

  dispatch(actions.setActiveWaypointIdx(inputIdx))
}

export const changeTab = (props) => (dispatch, getState) => {
  dispatch(actions.changeTab(props))

  getState();

  dispatch(updateRoute())
  dispatch(reclculateActiveWaypointIdx())
}
