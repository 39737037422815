import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { vesselsSlice } from './slices/vesselSlice'
import { createVesselSlice } from './slices/createVesselSlice'
import { routeInputsSlice } from './slices/routeInputsSlice'
import { mapSlice } from './slices/mapSlice'
import { voyageSlice } from './slices/VoyageSlice'
import { routePSlice } from './slices/routePreferencesSlice'
import { resultSlice } from './slices/resultSlice'
import { operationalConditionSlice } from './slices/operationalConditionSlice'
import { addNewVoyageSlice } from './slices/addNewVoyageSlice'
import {calculationSlice} from './slices/calculationSlice'
import {initSlice, getInit} from './slices/initSlice'
import { seriesSlice } from './slices/seriesSlice'

const store = configureStore({
  reducer: {
    init: initSlice.reducer,
    vessels: vesselsSlice.reducer,
    createVessel: createVesselSlice.reducer,
    routeInputs: routeInputsSlice.reducer,
    map: mapSlice.reducer,
    voyages: voyageSlice.reducer,
    routePreferences: routePSlice.reducer,
    operationalCondition: operationalConditionSlice.reducer,
    result: resultSlice.reducer,
    newVoyage: addNewVoyageSlice.reducer,
    calculation: calculationSlice.reducer,
    series: seriesSlice.reducer
  },
  middleware: [thunkMiddleware, ...getDefaultMiddleware()],
})

store.dispatch(getInit())
export default store
