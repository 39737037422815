import { createSlice } from '@reduxjs/toolkit'
import {saveSeries} from '../api'
import { fetchVessels } from './vesselSlice'

export const seriesSlice = createSlice({
  name: 'series',
  initialState: {
    isLoading: false,
    isError: false,
    data: [{
      imo: 'imo 1',
      name: 'name 1'
    },{
      imo: 'imo 2',
      name: 'name 2'
    }],
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
      if (action.payload) {
        state.isError = false
      }
    },

    setIsError: (state, action) => {
      state.isError = !!action.payload
    },

    setVessels: (state, action) => {
      state.data = action.payload
    },
  },
})

const { actions } = seriesSlice


export const addToSeries = (data) => {
  return (dispatch) => {
    dispatch(actions.setIsLoading(true))
    return saveSeries(data)
      .then(() => {
        //dispatch(actions.setIsLoading(true))

        //dispatch(actions.setVessels(vessels))

        dispatch(actions.setIsLoading(false))

        dispatch(fetchVessels())
      })
      .catch((e) => dispatch(actions.setIsError(true)))
  }
}
