import axios from 'axios'

function axiosWrapper(axs) {
  return {
    get: (url) => new Promise((resolve, reject) => {
        axs.get(url)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            if(err.response &&
              err.response.status === 401 &&
              err.response.headers.location
              ) {

                window.location = err.response.headers.location;
              }

              reject(err)
          })
      })

  }
}

export const getVessels = () => axios.get(`/api/vessels/`)
export const createVessel = (vessel) => {
  return axios.post(`/api/vessels/`, vessel)
}

export const getPorts = (text) => axios.get(`/api/ports/?text=${text}`)
export const getRoute = (coords) => axios.post('/api/route/coords',  coords )
export const getArchiveVoyages = () => axios.get(`/api/voyages`)
export const doCalculation = (data) => axios.post('/api/calculation/do', data)
export const cloneVoyage = (id) => axios.post('/api/voyages/clone', {id})

export default {
  calculation: {
    do: (data) => axios.post('/api/calculation/do', data),
    getResult: (id) => axios.get('/api/calculation/getresult/'+id),
    accept: (id) => axios.put(`/api/calculation/accept/${id}`)
  },

  getInit: () => axiosWrapper(axios).get('/api/init')
}

export const getHexs = () => axios.get('/hexs.json')
export const saveSeries = (data) => axios.post('/api/vessels/series', data)
