import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { notification } from 'antd'

const initialState = {
  isLoading: false,
  isError: false,

  data: {
    imo: '',
    vesselName: '',
    voyageName: '',
    from: '',
    to: '',

    maxWaveheight: null,
    operability: null,
    hsMaxuMatters: true,

    standardLocationsCssCodeAccelerations: {
      ax: [],
      ay: [],
      az: [],
    },

    standardPointsAccclerationsLimits: {
      ax: [],
      ay: [],
      az: [],
    },

    cssCodeAccelerationsForAdditionalLocations: [],
    reducedAccelerationsForAdditionalLocations: [],
    replacedByRawHindcast: [],
    polygons: [],
    scatterTable: null,
    seasonStartDate: null,
    seasonEndDate: null,
  },

  backAvailable: true
}

export const resultSlice = createSlice({
  name: 'result',
  initialState: {...initialState},

  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = !!action.payload
      if (action.payload) {
        state.isError = false
      }
    },

    setIsError: (state, action) => {
      state.isError = !!action.payload
    },

    setResult: (state, action) => {
      state.data = action.payload
    },

    disableBackButton: (state, action) => {
      state.backAvailable = false
    },

    clearReplacedByRawHindcast: (state, action) => {
      state.data.replacedByRawHindcast = []
      state.data.polygons = []
    },

    clearResult: (state) => {
      const newState = {...initialState};
      return newState;
    }
  },
  extraReducers: {
    "voyage/copyVoyage": (state, action) => {
      state = {...initialState}

    }
  }
})

const { actions } = resultSlice

const format = (ax, ay, az) => ({
  ax: ax.map(a => [[a]]),
  ay: ay,
  az: [az]
})

export const fetchResult = (id) => (dispatch) => {
  dispatch(actions.setIsLoading(true))


  return api.calculation.getResult(id)
    .then(data => data.data)
    .then(data => {

      dispatch(actions.setResult({
        imo: data.imo,
        vesselName: data.vesselName,
        voyageName: data.voyageName,
        from: data.from,                //'Hamburg, Germany',
        to: data.to,                     //'Piraeus, Greece',

        maxWaveheight: data.maxWaveHeight,
        maxWaveHeightUnlimited: data.maxWaveHeightUnlimited,
        hsMaxuMatters: data.hsMaxuMatters,
        forcedMinHsMax: data.forcedMinHsMax,
        operability: data.operability,

        cssCodeAccelerationsForAdditionalLocations: data.cssCodeAccelerationsForAdditionalLocations,
        reducedAccelerationsForAdditionalLocations: data.reducedAccelerationsForAdditionalLocations,

        standardLocationsCssCodeAccelerations: format(
          data.standardLocationsCssCodeAccelerationsAx,
          data.standardLocationsCssCodeAccelerationsAy,
          data.standardLocationsCssCodeAccelerationsAz),

          standardPointsAccclerationsLimits: format(
            data.standardPointsAccclerationsLimitsAx,
            data.standardPointsAccclerationsLimitsAy,
            data.standardPointsAccclerationsLimitsAz
          ),
          replacedByRawHindcast: data.replacedByRawHindcast,
          polygons: data.polygons,
          scatterTable: data.scatterTable,
          seasonStartDate: data.seasonStartDate,
          seasonEndDate: data.seasonEndDate,
          scatterTableColors: data.scatterTableColors,
          }))

          dispatch(actions.setIsLoading(false))
    })
    .catch(err => {
      notification['error']({
        message: 'We have a little problem.',
        description: 'We encountered an unexpected error.',
        style: {
          backgroundColor: '#fff2f0',
          border: '1px solid #ffccc7'
        },
        duration: 9
      })

      dispatch(actions.setIsLoading(false))
      dispatch(actions.clearResult())
    })
}

export const disableBackButton = () => actions.disableBackButton()

export const clearReplacedByRawHindcast = () => (dispatch) => {
  dispatch(actions.clearReplacedByRawHindcast());

  return Promise.resolve();
}
